import axios, { Axios } from "axios";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { safePreventDefault } from "react-slick/lib/utils/innerSliderUtils";
import { toast } from "react-toastify";
import { host } from "../config/host";
import TestyechishQism from "./TestyechishQism";

export default class Testing extends Component {
  state = {
    testlar: [],
    firstname: "",
    lastname: "",
    group: "",
    next: false,
    show: false,
    kId: 0,
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleOk = () => {
    this.setState({
      show: false,
    });
    this.getStudent();
  };
  // handleFirsname = (evt) => {
  //   this.setState({
  //     firstname: evt.target.value,
  //   });
  // };
  // handleLastname = (evt) => {
  //   this.setState({
  //     lastname: evt.target.value,
  //   });
  // };
  // handleGroup = (evt) => {
  //   this.setState({
  //     group: evt.target.value,
  //   });
  // };
  getStudent = () => {
    var firstname = document.querySelector("#firstname").value;
    var lastname = document.querySelector("#lastname").value;
    var group = document.querySelector("#group").value;
    if (firstname.length > 0 && lastname.length > 0 && group.length > 0) {
      axios
        .get(`${host}/tests/test/`, {
          params: { first_name: firstname, last_name: lastname, group: group },
        })
        .then((res) => {
          this.setState({
            testlar: res.data,
            next: true,
          });
          console.log(res.data);
          toast.success("Yes siz ro'yxatdan o'tdingiz ");
        })
        .catch((res) => {
          console.log("error");
        });
    } else {
      toast.error("Formani to'ldiring aks holda test yecholmaysiz ");
    }
  };
  // Checked = () => {
  //   Checkbox = document.querySelectorAll(".form-check");
  //   Checkbox.map((item11) => {
  //     console.log(item11);
  //   });
  // };

  // counter = () => {
  //   if (this.state.kId < this.state.testlar.tests.length) {
  //     this.setState({
  //       kId: this.state.kId + 1,
  //     });
  //   }
  // };

  // componentDidMount() {
  //   this.counter();
  // }

  render() {
    const { testlar, kId } = this.state;

    return (
      <>
        {this.state.next !== true ? (
          <div>
            <div className="container my-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card p-4">
                    <h1>Electironika</h1>
                    <div>
                      <Button variant="primary" onClick={this.handleShow}>
                        Test yechish
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Talabalar so'rovnomani to'ldiring</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="First name"
                      id="firstname"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="Last name"
                      id="lastname"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="Group"
                      id="group"
                    />
                  </div>
                </form>
                <i style={{ color: "red" }}>O'zingiz haqida to'liq kiriting</i>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleOk}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div>
            <div className="container">
              <div className="row my-5 justify-content-center">
                <h2 className="text-center">
                  {testlar.tests[0].question.subject.name}
                </h2>
                <div className="col-lg-8">
                  <div className="card text-center my-5 p-3">
                    <h2>
                      {testlar.student.first_name} {testlar.student.last_name}
                    </h2>
                    <p>{testlar.date_created}</p>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="card p-5">
                    {testlar.tests.length
                      ? testlar.tests.map((item, index) => (
                          <div key={index}>
                            <h5 id={`${item.pk}`}>{item.question.question}</h5>
                            <div>
                              {item.question.answers_set.map((item1) => (
                                <div>
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      id={`${item1.pk}`}
                                      name={`${item.pk}`}
                                      style={{ cursor: "pointer" }}
                                      // onClick={this.Checkbox}
                                    />
                                    <label
                                      class="form-check-label"
                                      for={`${item1.pk}`}
                                      name={`${item.pk}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item1.answer}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))
                      : ""}

                    <div className="text-center">
                      <button className="btn btn-outline-primary">
                        Finished
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
